import styled from "styled-components";
import { color, layout, opacity, space, typography } from "styled-system";

import {
  fill,
  hoverBg,
  hoverColor,
  hoverFill,
  hoverOpacity,
  pointerEvents,
  whiteSpace
} from "../../utils/customProperties";

export const LinkElement = styled.a`
  ${color};
  ${fill};
  ${layout};
  ${opacity};
  ${space};
  ${typography};
  ${whiteSpace};
  ${pointerEvents};
  cursor: pointer;
  text-decoration: none;
  transition-duration: 150ms;

  &:hover {
    ${hoverBg};
    ${hoverColor};
    ${hoverFill};
    ${hoverOpacity};
  }
`;
