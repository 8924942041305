import styled from "styled-components";

import Box from "../Box";

const Wrapper = styled(Box)`
  max-width: 1360px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export default Wrapper;
