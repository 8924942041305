import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { withTheme } from "styled-components";

const AnimateIntoView = ({ animateOpacity = true, theme, ...props }) => {
  const [ref, inView] = useInView({
    // https://github.com/thebuilder/react-intersection-observer#options
    threshold: 0,
    triggerOnce: true
  });

  const variants = {
    hide: {
      opacity: animateOpacity ? 0 : 1,
      translateY: "200px"
    },
    show: {
      opacity: 1,
      translateY: "0px"
    }
  };

  return (
    <motion.div
      {...props}
      ref={ref}
      variants={variants}
      animate={inView ? "show" : "hide"}
      transition={{
        ease: theme.easings.smooth.array,
        duration: 1.0
      }}
    />
  );
};

export default withTheme(AnimateIntoView);
