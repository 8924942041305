import React from "react";
import { motion } from "framer-motion";
import { withTheme } from "styled-components";

import Box from "../Box";

const AnimateTextUp = ({
  startAnimation = false,
  lineHeight = 70,
  delay = 0.0,
  theme,
  children
}) => {
  const initial = { y: lineHeight, opacity: 0 };
  const animate = { y: 0, opacity: 1 };
  const transition = {
    duration: 0.5,
    delay: delay,
    ease: theme.easings.smooth.array
  };

  return (
    <Box width={1} overflow="hidden">
      <motion.div
        initial={initial}
        overflow={"hidden"}
        animate={startAnimation && animate}
        transition={{ ...transition }}
      >
        {children}
      </motion.div>
    </Box>
  );
};

export default withTheme(AnimateTextUp);
