const kinds = {
  none: {},
  normal: {
    color: "black",
    hoverOpacity: 0.8
  },
  light: {
    color: "white",
    hoverOpacity: 0.8
  }
};

export default kinds;
