import React from "react";
import { useInView } from "react-intersection-observer";

import Text from "../../../components/Text";
import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import Wrapper from "../../../components/Wrapper";
import AnimateSlideIn from "../../../components/AnimateSlideIn";
import AnimateTextUp from "../../../components/AnimateTextUp";
import LazyImage from "../../../components/LazyImage";

import manBike from "./images/man-bike.jpg";
import manBike2x from "./images/man-bike@2x.jpg";

import womanDrink from "./images/woman-drink.jpg";
import womanDrink2x from "./images/woman-drink@2x.jpg";

function MagazineBlock() {
  const [ref, inView] = useInView({
    // https://github.com/thebuilder/react-intersection-observer#options
    threshold: 0,
    triggerOnce: true
  });

  return (
    <Box bg="white">
      <Wrapper mt={[5, 6, 6, 6]} minHeight={910} px={4}>
        <Flex
          alignItems="stretch"
          justifyContent="stretch"
          flexDirection={["column", "column", "column", "row"]}
          width={1}
        >
          <Box>
            <Text
              pb={[3, 4, 4, 4]}
              fontSize={[4, 5, 6, 6]}
              letterSpacing={[-1, -2, -2, -2]}
              color="black"
              fontFamily="gt"
              fontWeight="bold"
              lineHeight={1.2}
              maxWidth={680}
              as="h2"
              ref={ref}
            >
              <AnimateTextUp startAnimation={inView}>
                Harness the power
              </AnimateTextUp>
              <AnimateTextUp startAnimation={inView} delay={0.2}>
                of habit to create
              </AnimateTextUp>
              <AnimateTextUp startAnimation={inView} delay={0.4}>
                a culture of sustainability
              </AnimateTextUp>
              <AnimateTextUp startAnimation={inView} delay={0.6}>
                in your company.
              </AnimateTextUp>
            </Text>
            <Text
              fontSize={[2, 2, 3, 3]}
              color="black"
              fontFamily="roboto"
              fontWeight="regular"
              lineHeight={1.5}
              maxWidth={465}
              as="p"
            >
              With both a fun and inspiring approach, Greenlist gives your
              workforce the power of changing their habits by making daily
              choices that have less harmful effects on the environment.
            </Text>
            <Box mt={[5, 5, 5, 6]} mb={[0, 0, 0, -5]}>
              <AnimateSlideIn animateOpacity={false}>
                <LazyImage
                  src={manBike}
                  alt="Man with his bike in his office"
                  srcSet={manBike + " 1x," + manBike2x + " 2x"}
                  width={[300, 557]}
                  height={[200, 371]}
                />
              </AnimateSlideIn>
            </Box>
          </Box>
          <Flex
            flex="1"
            justifyContent={["flex-end", "center"]}
            alignItems="center"
          >
            <Box
              width={[252, 252, 252, 252, 391]}
              height={[342, 342, 342, 342, 531]}
              my={[4, 5]}
            >
              <AnimateSlideIn animateOpacity={false}>
                <LazyImage
                  src={womanDrink}
                  alt="Woman drinking from a reusable bottle"
                  srcSet={womanDrink + " 1x," + womanDrink2x + " 2x"}
                  width={[252, 252, 252, 252, 391]}
                  height={[342, 342, 342, 342, 531]}
                />
              </AnimateSlideIn>
            </Box>
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
}

export default MagazineBlock;
