export function amountToReadable(amount) {
  const dollars = amount / 100;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  });

  return formatter.format(dollars);
}

export default { amountToReadable };
