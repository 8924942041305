// Make sure you set those in Netlify when setting up a new account on it

export const stripePublishableKey = process.env.REACT_APP_STRIPE_KEY
  ? process.env.REACT_APP_STRIPE_KEY
  : "pk_test_G7CYrylzScPtDGg53q5kbdHE00rHZpyzwK";
export const apiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://climateapp.ngrok.io/";
export const redirectUrl = process.env.REACT_APP_STRIPE_SUCCESS_URL
  ? process.env.REACT_APP_STRIPE_SUCCESS_URL
  : "https://climateweb.ngrok.io/manage/";
