import styled from "styled-components";
import { space, color, typography, layout, border } from "styled-system";

export const SelectElement = styled.select`
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3e%3cg stroke='%239B9B9B' fill='none' fill-rule='evenodd' stroke-linecap='square'%3e%3cpath d='M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12z'/%3e%3cpath d='M16 10l-4 4-4-4'/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat, repeat;
  background-position: right 8px top 50%, 0 0;
  background-size: ${props => props.iconSize}px, ${props => props.iconSize}px;

  ::-ms-expand {
    display: none;
  }

  :hover {
    border-color: #888;
  }
  :focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  option {
    font-weight: normal;
  }

  box-sizing: border-box;

  ${typography};
  ${border};
  ${color};
  ${layout};
  ${space};
`;
