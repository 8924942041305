import { apiUrl } from "./environment";

function headers({ token = null }) {
  var headers = {
    "Content-Type": "application/json"
  };

  if (token !== null) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
}

export function stripeCreateSession({ planId, successUrl, cancelUrl, token }) {
  return fetch(apiUrl + "stripe/create-session", {
    method: "POST",
    headers: headers({ token }),
    body: JSON.stringify({
      plan_id: planId,
      success_url: successUrl,
      cancel_url: cancelUrl
    })
  });
}

export function cancelAllSubscriptions({ token }) {
  return fetch(apiUrl + "stripe/cancel-subscriptions", {
    method: "DELETE",
    headers: headers({ token })
  });
}

export function fetchUser({ token }) {
  return fetch(apiUrl + "user", {
    method: "GET",
    headers: headers({ token })
  });
}

export function updateUser({ schedule, token }) {
  return fetch(apiUrl + "user", {
    method: "POST",
    headers: headers({ token }),
    body: JSON.stringify({ schedule })
  });
}

export function fetchPlans() {
  return fetch(apiUrl + "stripe/available-plans", {
    method: "GET",
    headers: headers({})
  });
}

export function allSlackChannels({ token }) {
  return fetch(apiUrl + "slack/channels", {
    method: "GET",
    headers: headers({ token })
  });
}

export function availableTimeZones() {
  return fetch(apiUrl + "available-timezones", {
    method: "GET",
    headers: headers({})
  });
}
