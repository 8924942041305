import React from "react";

import Box from "../../components/Box";
import Menu from "../../components/Menu";
import Text from "../../components/Text";
import Page from "../../components/Page";
import Flex from "../../components/Flex";
import Wrapper from "../../components/Wrapper";
import PlansCard from "../../components/PlansCard";
import BigCopyBlock from "../../components/BigCopyBlock";
import SlackButton from "../../components/SlackButton";

import Questions from "./Questions";

function Pricing() {
  return (
    <Page
      metaData={{
        title: "A small price for a big change"
      }}
    >
      <Box bg="green">
        <Menu />
        <Wrapper px={4}>
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            mt={5}
          >
            <Box>
              <Text
                pb={3}
                letterSpacing={-2}
                fontSize={[5, 6, 7, 7]}
                color="white"
                fontFamily="gt"
                fontWeight="bold"
                lineHeight={1.1}
                textAlign="center"
                maxWidth={948}
                marginLeft="auto"
                marginRight="auto"
                as="h1"
              >
                A small price for a{" "}
                <Box display="inline-block" color="pink">
                  big change.
                </Box>
              </Text>
            </Box>
            <PlansCard mt={4} mb={-5}>
              <SlackButton width="100%" mt={3} />
            </PlansCard>
          </Flex>
        </Wrapper>
      </Box>
      <Questions my={[6, 6, 7, 7]} />
      <BigCopyBlock mt={[4, 4, 6, 6]}>Let your friends know.</BigCopyBlock>
    </Page>
  );
}

export default Pricing;
