import React from "react";
import PropTypes from "prop-types";

import { Route, Switch, withRouter } from "react-router-dom";

import { AuthContext } from "./context/auth";

import GlobalStyles from "./GlobalStyles";
import Login from "./Login";
import Logout from "./Logout";
import PrivateRoute from "./PrivateRoute";
import Home from "./pages/Home";
import HomeV2 from "./pages/HomeV2";
import HomeV3 from "./pages/HomeV3";
import Terms from "./pages/Terms";
import Pricing from "./pages/Pricing";
import Privacy from "./pages/Privacy";
import Manage from "./pages/Manage";

import { usePersistedState } from "./utils/usePersistedState";

function App(props) {
  const tokenKey = "token";
  const [authToken, setAuthToken] = usePersistedState(null, tokenKey);

  const setToken = token => {
    setAuthToken(token);
  };

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
      <GlobalStyles />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/variant" component={HomeV2} />
        <Route exact path="/variant2" component={HomeV3} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/terms" component={Terms} />
        <PrivateRoute exact path="/manage" component={Manage} />
      </Switch>
    </AuthContext.Provider>
  );
}

App.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(App);
