import React from "react";

import Svg from "../Svg";

function SlackIcon({ fill = "#000" }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      display="block"
    >
      <g fill={fill} fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          d="M5.07 2.535a2.483 2.483 0 01-2.478 2.477A2.483 2.483 0 01.115 2.535 2.483 2.483 0 012.592.058H5.07v2.477zM6.317 2.535A2.483 2.483 0 018.794.058a2.483 2.483 0 012.477 2.477v6.202a2.483 2.483 0 01-2.477 2.477 2.483 2.483 0 01-2.477-2.477V2.535z"
          transform="translate(-644 -4827) translate(0 4421) translate(393 107) translate(235 284) translate(16 15) translate(0 12.48)"
        ></path>
        <path
          d="M8.794 5.07a2.483 2.483 0 01-2.477-2.478A2.483 2.483 0 018.794.115a2.483 2.483 0 012.477 2.477V5.07H8.794zM8.794 6.317a2.483 2.483 0 012.477 2.477 2.483 2.483 0 01-2.477 2.477H2.592A2.483 2.483 0 01.115 8.794a2.483 2.483 0 012.477-2.477h6.202z"
          transform="translate(-644 -4827) translate(0 4421) translate(393 107) translate(235 284) translate(16 15)"
        ></path>
        <g>
          <path
            d="M6.26 8.794a2.483 2.483 0 012.477-2.477 2.483 2.483 0 012.477 2.477 2.483 2.483 0 01-2.477 2.477H6.26V8.794zM5.012 8.794a2.483 2.483 0 01-2.477 2.477A2.483 2.483 0 01.058 8.794V2.592A2.483 2.483 0 012.535.115a2.483 2.483 0 012.477 2.477v6.202z"
            transform="translate(-644 -4827) translate(0 4421) translate(393 107) translate(235 284) translate(16 15) translate(12.48)"
          ></path>
        </g>
        <g>
          <path
            d="M2.535 6.26a2.483 2.483 0 012.477 2.477 2.483 2.483 0 01-2.477 2.477A2.483 2.483 0 01.058 8.737V6.26h2.477zM2.535 5.012A2.483 2.483 0 01.058 2.535 2.483 2.483 0 012.535.058h6.202a2.483 2.483 0 012.477 2.477 2.483 2.483 0 01-2.477 2.477H2.535z"
            transform="translate(-644 -4827) translate(0 4421) translate(393 107) translate(235 284) translate(16 15) translate(12.48 12.48)"
          ></path>
        </g>
      </g>
    </Svg>
  );
}

export default SlackIcon;
