import styled from "styled-components";

import {
  layout,
  flexbox,
  borderColor,
  borderRadius,
  borders,
  color,
  shadow,
  opacity,
  position,
  space,
  typography,
  zIndex
} from "styled-system";

import {
  hoverBg,
  hoverBoxShadow,
  hoverColor,
  hoverFill,
  pointerEvents,
  transform
} from "../../utils/customProperties";

const Box = styled.div`
  ${layout};
  ${flexbox};
  ${borderRadius};
  ${borders};
  ${borderColor};
  ${shadow};
  ${color};
  ${typography};
  ${opacity};
  ${position};
  ${space};
  ${zIndex};
  ${transform};
  ${pointerEvents};
  box-sizing: border-box;

  &:hover {
    ${hoverBg};
    ${hoverBoxShadow};
    ${hoverColor};
    ${hoverFill};
  }
`;

export default Box;
