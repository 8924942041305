import React, { useState, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { withTheme } from "styled-components";

import Image from "../Image";
import Box from "../Box";

function LazyImage({
  width = 300,
  height = 300,
  src,
  alt,
  srcSet,
  theme,
  ...rest
}) {
  const [loaded, setLoaded] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true
  });

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  const variants = {
    hiddenloader: {
      height: 0
    },
    visibleloader: {
      height: height
    }
  };

  return (
    <Box width={width} height={height} ref={ref} {...rest}>
      <Image
        width={width}
        height={height}
        onLoad={onLoad}
        src={src}
        alt={alt}
        srcSet={srcSet}
        position="absolute"
      />
      <Box position="absolute" width={width} height={height} overflow="hidden">
        <motion.div
          variants={variants}
          animate={inView && loaded ? "hiddenloader" : "visibleloader"}
          initial={false}
          style={{
            backgroundColor: theme.colors.pink
          }}
          transition={{
            duration: 0.8,
            delay: 0.2,
            ease: theme.easings.smooth.array
          }}
        ></motion.div>
      </Box>
    </Box>
  );
}

export default withTheme(LazyImage);
