export const sizes = [
  {
    height: 26,
    borderRadius: 13,
    pl: 10,
    pr: 25,
    fontSize: 1,
    iconSize: 14
  },
  {
    height: 40,
    borderRadius: 20,
    pl: 15,
    pr: 37,
    fontSize: 2,
    iconSize: 24
  }
];
