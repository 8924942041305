import React from "react";

import ClassicLink from "../Link";
import { Link as RouterLink, NavLink as RouterNavLink } from "react-router-dom";

export default function Link(props) {
  const { to, activeClassName, ...restProps } = props;
  const isExternal = /^https?:\/\//.test(to);
  const isEmailAddress = /^mailto:/.test(to);

  if (isExternal || !to) {
    restProps.tag = "a";
    restProps.href = restProps.href || to;
    restProps.target = restProps.target || "_blank";
  } else if (isEmailAddress) {
    restProps.tag = "a";
    restProps.href = to;
  } else if (activeClassName) {
    restProps.tag = RouterNavLink;
    restProps.to = to;
    restProps.activeClassName = activeClassName;
  } else {
    restProps.tag = RouterLink;
    restProps.to = to;
  }

  return <ClassicLink {...restProps} />;
}
