import React from "react";

import { motion } from "framer-motion";

import Image from "../../../components/Image";
import Box from "../../../components/Box";

function Card({
  image1x,
  image2x,
  imageAlt,
  avatar1x,
  avatar2x,
  avatarAlt,
  distance = 16,
  size = 0,
  width = 322,
  avatarSize = 60,
  ...rest
}) {
  return (
    <Box width={width} {...rest}>
      <motion.div
        animate={{
          y: [distance, 0, distance]
        }}
        transition={{
          ease: "easeInOut",
          loop: Infinity,
          duration: 4
        }}
      >
        <Box
          borderRadius="3%"
          overflow="hidden"
          display="inline-block"
          boxShadow="0 20px 60px 0 rgba(0,0,0,0.10)"
        >
          <Image
            src={image1x}
            alt={imageAlt}
            srcSet={image1x + " 1x," + image2x + " 2x"}
            width={width}
          />
        </Box>
        <Box
          borderRadius="15%"
          overflow="hidden"
          display="inline-block"
          position="absolute"
          left="-8%"
          top="-8%"
          width="19%"
          maxWidth={60}
        >
          <Image
            src={avatar1x}
            alt={avatarAlt}
            srcSet={avatar1x + " 1x," + avatar2x + " 2x"}
            width="100%"
            height="100%"
          />
        </Box>
      </motion.div>
    </Box>
  );
}

export default Card;
