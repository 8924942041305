import React from "react";

import Box from "../../components/Box";
import Text from "../../components/Text";
import Grid from "../../components/Grid";
import Wrapper from "../../components/Wrapper";

function Questions({ ...rest }) {
  var item = ({ title, description }) => {
    return (
      <Box maxWidth={543}>
        <Text
          fontSize={[3, 3, 4, 4]}
          color="black"
          fontFamily="roboto"
          as="h3"
          fontWeight="bold"
          textAlign={["center", "center", "center", "left"]}
        >
          {title}
        </Text>
        <Text
          fontSize={[1, 1, 2, 2]}
          mt={4}
          color="black"
          lineHeight={1.7}
          fontFamily="roboto"
          fontWeight="regular"
          as="p"
          textAlign={["center", "center", "center", "left"]}
        >
          {description}
        </Text>
      </Box>
    );
  };
  return (
    <Box {...rest}>
      <Wrapper px={4}>
        <Text
          my={[5, 5, 6, 6]}
          fontSize={[4, 4, 5, 5]}
          letterSpacing={[-1, -1, -2, -2]}
          color="black"
          fontFamily="gt"
          as="h2"
          fontWeight="bold"
          lineHeight={1.1}
          textAlign="center"
        >
          Your questions, answered.
        </Text>
        <Grid
          justifyContent="center"
          gridTemplateColumns={["auto", "auto", "auto", "auto auto"]}
          gridColumnGap={5}
          gridRowGap={5}
          mx="auto"
        >
          {item({
            title: "What exactly is Greenlist?",
            description:
              "Greenlist is a Slack Integration for companies that turns cutting down on plastic into a friendly in-company competition. Thanks to its fun and inspiring approach, Greenlist is the perfect tool to educate employees on sustainability while keeping them engaged so that they feel proud of the quantifiable difference they are making towards the plastic pollution crisis."
          })}
          {item({
            title: "How does Greenlist work?",
            description:
              "At a set time of the day, the app triggers in a selected Slack channel a poll in which employees can register which environmental friendly actions they’ve taken during the day. Every following day the app posts in the same channel a ranking board of the past 30 days based on the actions taken. The Greenlist bot will direct message the users when they unlock a badge, with information on how their actions are contributing to reduce their footprint."
          })}
          {item({
            title: "Why do you charge for Greenlist?",
            description:
              "We run Greenlist on our (very little) spare time and your support is crucial to let us improve and invest on the app. Our aim is to use tech to drive a positive change in the world and this is why we are donating 10% of every subscription plan to coolearth.org to help plant trees around the world."
          })}
          {item({
            title: "Do you have a free plan?",
            description:
              "You can try Greenlist free of charge for the first 14 days before getting charged. Plans start at $7/month and vary depending on the number of users. You can cancel your subscription at any time."
          })}
          {item({
            title: "How does the the point system work?",
            description:
              "After hours of research, we’ve ranked the most effective actions that people can take in their day-to-day life to reduce their carbon footprint and assigned a score accordingly. However, we are not experts and we’re happy to hear feedback on how to improve the scoring system. "
          })}
          {item({
            title: "Can I get help if this app doesn’t work?",
            description:
              "Sure! We’re here to help. Reach out to us on support@greenlistapp.com and we will get back to you as soon as we can."
          })}
        </Grid>
      </Wrapper>
    </Box>
  );
}

export default Questions;
