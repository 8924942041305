import styled from "styled-components";
import { motion } from "framer-motion";

export const MotionSVGElement = styled(motion.svg)`
  overflow: visible;
  stroke: #f09c96;
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-linecap: round;
`;
