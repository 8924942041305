export const links = [
  {
    to: "/",
    title: "Home"
  },
  {
    to: "/pricing",
    title: "Pricing"
  },
  {
    to: "mailto: support@greenlistapp.com",
    title: "Support"
  }
];
