import React from "react";
import { apiUrl } from "../../environment";

import RouterLink from "../RouterLink";
import Button from "../Button";
import Box from "../Box";

import SlackIcon from "./SlackIcon";

function SlackButton({ isDark = true, ...rest }) {
  return (
    <Box display="inline-block">
      <RouterLink to={`${apiUrl}signup/slack`} mt={3} {...rest}>
        <Button
          accessory={
            <Box mr={2}>
              <SlackIcon fill={isDark ? "#FFF" : "#023A15"} />
            </Box>
          }
          isDark={isDark}
        >
          Connect to Slack
        </Button>
      </RouterLink>
    </Box>
  );
}

export default SlackButton;
