import React from "react";

import Logo from "../../components/Logo";
import Flex from "../../components/Flex";
import Box from "../../components/Box";
import Text from "../../components/Text";
import Wrapper from "../../components/Wrapper";

import HeaderItem from "./HeaderItem";

var Header = () => {
  return (
    <Box bg="white" px={4}>
      <Wrapper>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          pt={3}
        >
          <Box mt={5} mb={5}>
            <Logo isDark={true} />
          </Box>
          <Text
            pb={[2, 2, 2, 3]}
            mt={2}
            mx="auto"
            fontSize={[5, 5, 5, 6]}
            letterSpacing={[-1, -1, -1, -2]}
            color="black"
            fontFamily="gt"
            fontWeight="bold"
            textAlign="center"
            lineHeight={1.2}
            maxWidth={620}
          >
            Welcome to the Greenlist family
          </Text>
          <Flex flexDirection="row" justifyContent="center" mt={4} mb={6}>
            <HeaderItem to="#settings" isExternal>
              Settings
            </HeaderItem>
            <HeaderItem to="#badges" isExternal>
              Badges
            </HeaderItem>
            <HeaderItem to="#subscription" isExternal>
              Subscription
            </HeaderItem>
            <HeaderItem to="mailto: support@greenlistapp.com" isExternal>
              Support
            </HeaderItem>
            <HeaderItem to="/logout" isExternal>
              Logout
            </HeaderItem>
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
};

export default Header;
