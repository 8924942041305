import React from "react";

import Box from "../Box";
import Flex from "../Flex";
import Wrapper from "../Wrapper";
import RouterLink from "../RouterLink";

import Logo from "../Logo";
import Item from "./Item";

import { links } from "./links";

function Menu() {
  return (
    <Box pt={4}>
      <Wrapper px={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Flex>
            {links.map(item => (
              <Item key={item.to} to={item.to} isExternal={item.isExternal}>
                {item.title}
              </Item>
            ))}
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
}
export default Menu;
