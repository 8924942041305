import React from "react";

import { CheckboxElement } from "./styles";

function Checkbox({ checked, onChange, label, ...rest }) {
  return (
    <CheckboxElement onClick={() => onChange(!checked)} {...rest}>
      <input type="checkbox" checked={checked} />
      <span className="check"></span>
    </CheckboxElement>
  );
}

export default Checkbox;
