import React from "react";

import Flex from "../../components/Flex";
import Box from "../../components/Box";
import Text from "../../components/Text";
import Wrapper from "../../components/Wrapper";

import SubscriptionCard from "./SubscriptionCard";

var Subscription = () => {
  return (
    <Box bg="green" id="subscription" px={4}>
      <Wrapper>
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          py={6}
        >
          <Text
            pb={[4, 4, 4, 5]}
            fontSize={[3, 3, 3, 4]}
            letterSpacing={[-1, -1, -1, -1]}
            color="white"
            fontFamily="gt"
            fontWeight="bold"
            textAlign={["center", "center", "left", "left"]}
            lineHeight={1.2}
          >
            Your subscription
          </Text>
          <SubscriptionCard />
        </Flex>
      </Wrapper>
    </Box>
  );
};

export default Subscription;
