import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export default createGlobalStyle`

    ${reset}

    body {
      -webkit-font-smoothing: antialiased;
  	  -moz-osx-font-smoothing: grayscale;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
    }

`;
