import React from "react";

import Box from "../../../components/Box";
import AspectRatio from "../../../components/AspectRatio";

import Card from "./Card";
import Line from "./Line";

import cardReusableCup from "./images/card-reusable-cup.png";
import cardReusableCup2x from "./images/card-reusable-cup@2x.png";

import cardFoodContainer from "./images/card-food-container.png";
import cardFoodContainer2x from "./images/card-food-container@2x.png";

import avatarMan from "./images/avatar-man.png";
import avatarMan2x from "./images/avatar-man@2x.png";

import avatarWomanRed from "./images/avatar-woman-red.png";
import avatarWomanRed2x from "./images/avatar-woman-red@2x.png";

function AnimatedCards({ ...rest }) {
  const width = [300, 350, 500, 453, 558];

  return (
    <AspectRatio ratio={10 / 8.9} width={width} {...rest}>
      <Box position="absolute" top="0%" left="-2%" width={width} height="auto">
        <Line width={width} />
      </Box>
      <Box position="absolute" top={0} left={0} width="58%" maxWidth={322}>
        <Card
          image1x={cardReusableCup}
          image2x={cardReusableCup2x}
          imageAlt="Slack Card: Didn't use disposable cutlery"
          avatar1x={avatarWomanRed}
          avatar2x={avatarWomanRed2x}
          avatarAlt="Woman on a red background"
          distance={25}
          width="100%"
        />
      </Box>
      <Box position="absolute" bottom={0} right={0} width="58%" maxWidth={322}>
        <Card
          image1x={cardFoodContainer}
          image2x={cardFoodContainer2x}
          imageAlt="Slack Card: Didn't use any single-use food container"
          avatar1x={avatarMan}
          avatar2x={avatarMan2x}
          avatarAlt="Man on a pink background"
          width="100%"
        />
      </Box>
    </AspectRatio>
  );
}

export default AnimatedCards;
