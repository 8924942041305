/*global Stripe */

import React, { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import {
  stripeCreateSession,
  fetchUser,
  fetchPlans,
  cancelAllSubscriptions
} from "../../api";
import { stripePublishableKey, redirectUrl } from "../../environment";
import { useAuth } from "../../context/auth";

import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Text from "../../components/Text";
import Link from "../../components/Link";
import Card from "../../components/Card";
import PlansCard, { heightCard } from "../../components/PlansCard";

function SubscriptionCard() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(undefined);
  const [plansData, setPlansData] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { authToken } = useAuth();

  // Update the user data to latest
  var updateUser = useCallback(() => {
    fetchUser({ token: authToken })
      .then(resp => resp.json())
      .then(function(data) {
        setUserData(data);
        setIsLoading(false);
      })
      .catch(error => {
        if (error) {
          setIsLoading(false);
          setErrorMessage("Something went wrong getting your user data...");
        }
      });
  }, [authToken]);

  // Update the available plans latest
  var updatePlans = useCallback(() => {
    fetchPlans()
      .then(resp => resp.json())
      .then(function(data) {
        setPlansData(data);
        setIsLoading(false);
      })
      .catch(error => {
        if (error) {
          setIsLoading(false);
          setErrorMessage("Something went wrong fetching the plans...");
        }
      });
  }, []);

  useEffect(() => {
    updateUser();
    updatePlans();
  }, [updateUser, updatePlans]);

  // Requesting a Stripe session from the API
  var requestSubscription = function(planId) {
    setIsLoading(true);

    stripeCreateSession({
      planId: planId,
      successUrl: redirectUrl,
      cancelUrl: redirectUrl,
      token: authToken
    })
      .then(resp => resp.json())
      .then(function(data) {
        redirectToStripe(data.session_id);
      })
      .catch(error => {
        if (error) {
          console.log(error);
          setIsLoading(false);
          setErrorMessage("Requesting subscription info failed.");
        }
      });
  };

  // Cancel all current subscriptions
  var cancelSubscription = function() {
    setIsLoading(true);

    cancelAllSubscriptions({
      token: authToken
    }).then(resp => {
      if (resp.status === 200) {
        updateUser();
      } else {
        setIsLoading(false);
        setErrorMessage("Cancelling your subscription failed.");
      }
    });
  };

  // Redirect to Stripe to start the payment processing
  var redirectToStripe = function(sessionId) {
    console.log(sessionId);
    var stripe = Stripe(stripePublishableKey);

    stripe
      .redirectToCheckout({
        sessionId: sessionId
      })
      .then(function(result) {
        if (result.error) {
          setErrorMessage(result.error.message);
          setIsLoading(false);
        }
      });
  };

  if (errorMessage) {
    return (
      <Card justifyContent="center" alignItems="center" minHeight={heightCard}>
        <Text fontSize={2} textAlign="center">
          {errorMessage}
        </Text>
      </Card>
    );
  }

  if (isLoading || userData === undefined || plansData === undefined) {
    return (
      <Card justifyContent="center" alignItems="center" minHeight={heightCard}>
        <Loader isLoading={true} />
      </Card>
    );
  }

  const { subscription } = userData.team;

  if (subscription.waived) {
    return (
      <Card justifyContent="center" alignItems="center" minHeight={heightCard}>
        <Text
          textAlign="center"
          fontSize={3}
          color="black"
          fontFamily="gt"
          fontWeight="bold"
          mb={2}
        >
          You have a gifted subscription
        </Text>

        <Text mt={2} fontSize={2} lineHeight={1.5} textAlign="center">
          Enjoy your free subscription!
        </Text>
      </Card>
    );
  }

  if (subscription.is_canceled && subscription.active === true) {
    const currentPeriodEnd = moment
      .unix(subscription.current_period_end)
      .format("LL");

    return (
      <Card justifyContent="center" alignItems="center" minHeight={heightCard}>
        <Text
          textAlign="center"
          fontSize={3}
          color="black"
          fontFamily="gt"
          fontWeight="bold"
          mb={2}
        >
          Your subscription is canceled
        </Text>

        <Text mt={2} fontSize={2} lineHeight={1.5} textAlign="center" mx="auto">
          Your plan will end on {currentPeriodEnd}
        </Text>
      </Card>
    );
  }

  if (subscription.active && subscription.current_period_end) {
    const currentPeriodEnd = moment
      .unix(subscription.current_period_end)
      .format("LL");

    return (
      <Card justifyContent="center" alignItems="center" minHeight={heightCard}>
        <Text textAlign="center">
          <Text
            fontSize={3}
            color="black"
            fontFamily="gt"
            fontWeight="bold"
            mb={2}
          >
            Thank you for subscribing
          </Text>

          <Text
            mt={2}
            mb={4}
            fontSize={2}
            maxWidth={250}
            lineHeight={1.5}
            mx="auto"
          >
            Your subscription will renew on {currentPeriodEnd}
          </Text>

          <Link onClick={() => cancelSubscription()} color="pink">
            Cancel your subscription
          </Link>
        </Text>
      </Card>
    );
  }

  return (
    <>
      <PlansCard onChange={stripeId => setSelectedPlan(stripeId)}>
        <Link
          onClick={() => {
            requestSubscription(selectedPlan);
          }}
        >
          <Button mt={3}>Start subscription</Button>
        </Link>
      </PlansCard>
      <Text mt={4} fontSize={2} lineHeight={1.5} mx="auto" color="white">
        {subscription.active_trial && "You're currently on a 14 day trial"}
      </Text>
    </>
  );
}

export default SubscriptionCard;
