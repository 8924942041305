import React from "react";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#023A15"
        strokeLinecap="square"
        strokeWidth="2"
        d="M0 8L4 12 12 4"
        transform="translate(-564 -632) translate(526 427) translate(40 203)"
      ></path>
    </svg>
  );
}

export default CheckIcon;
