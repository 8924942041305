import React, { useState, useEffect, useCallback } from "react";
import { fetchPlans } from "../../api";
import stripe from "../../utils/stripe";

import Card from "../../components/Card";
import Box from "../../components/Box";
import Text from "../../components/Text";
import Loader from "../../components/Loader";
import Flex from "../../components/Flex";
import Select from "../../components/Select";
import AnimateFadeIn from "../../components/AnimateFadeIn";

import CheckIcon from "./CheckIcon";

export const heightCard = [412, 412, 460, 460];

function PlansCard({ children, onChange, ...rest }) {
  const [plansData, setPlansData] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const updatePlans = useCallback(() => {
    fetchPlans()
      .then(resp => resp.json())
      .then(function(data) {
        setPlansData(data);
        setIsLoading(false);
      })
      .catch(error => {
        if (error) {
          setErrorMessage("Something went wrong fetching the plans...");
          setIsLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    updatePlans();
  }, [updatePlans]);

  var ListItem = ({ children }) => {
    return (
      <Flex alignItems="center" py={2}>
        <CheckIcon />
        <Text
          ml={2}
          fontSize={2}
          color="black"
          fontFamily="roboto"
          fontWeight="regular"
        >
          {children}
        </Text>
      </Flex>
    );
  };

  if (errorMessage) {
    return (
      <Card
        {...rest}
        justifyContent="center"
        alignItems="center"
        minHeight={heightCard}
      >
        <AnimateFadeIn>
          <Text textAlign="center">{errorMessage}</Text>
        </AnimateFadeIn>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card
        {...rest}
        justifyContent="center"
        alignItems="center"
        minHeight={heightCard}
      >
        <Loader isLoading={true} />
      </Card>
    );
  }

  const selectedPlanItem = plansData[selectedPlan];

  onChange && onChange(selectedPlanItem.stripe_plan_id);

  return (
    <Card {...rest} minHeight={heightCard}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text
          fontSize={[2, 2, 2, 2]}
          color="black"
          fontFamily="roboto"
          fontWeight="medium"
        >
          Number of employees
        </Text>
        <Select
          size={1}
          value={selectedPlan}
          onChange={e => setSelectedPlan(e.target.value)}
        >
          >
          {plansData.map((number, index) => (
            <option key={number.stripe_plan_id} value={index}>
              Up to {number.max_users}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex
        justifyContent="flex-start"
        alignItems="flex-end"
        flexDirection="row"
        mt={1}
      >
        <Text fontFamily="gt" fontSize={[6, 6, 7, 7]}>
          {stripe.amountToReadable(selectedPlanItem.amount)}
        </Text>
        <Text
          fontSize={1}
          color="black"
          fontFamily="roboto"
          fontWeight="regular"
          mb="2px"
          ml={[0, 0, -2, -2]}
        >
          /Month
        </Text>
      </Flex>
      <Box mb={2} mt={3}>
        <ListItem>Greenlist Bot</ListItem>
        <ListItem>Up to {selectedPlanItem.max_users} seats</ListItem>
        <ListItem>Unlimited votes</ListItem>
        <ListItem>Email support</ListItem>
      </Box>
      {children}
    </Card>
  );
}

export default PlansCard;
