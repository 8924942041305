export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  purple: "#D7BEF8",
  lightPurple: "#F3EBFC",
  green: "#023A15",
  lightGreen: "#DDEEE4",
  pink: "#F09C96",
  lightPink: "#FFE6E2",
  gray: "#9B9B9B"
};

export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700
};

export const fonts = {
  roboto: "Roboto, Arial, Helvetica, sans-serif",
  gt: "GT, 'Palatino Linotype', 'Book Antiqua', Palatino, serif"
};

export const fontSizes = [11, 13, 16, 18, 25, 60, 70, 110];
export const space = [0, 4, 8, 16, 32, 64, 128, 256];
export const breakpoints = ["40em", "52em", "64em", "80em"];

export const easings = {
  bouncy: {
    string: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
    array: [0.68, -0.55, 0.265, 1.55]
  },
  smooth: {
    string: "cubic-bezier(0.23, 1, 0.32, 1)",
    array: [0.23, 1, 0.32, 1]
  }
};

// Theme
const theme = {
  breakpoints,
  colors,
  easings,
  fontSizes,
  fontWeights,
  space
};

export default theme;
