import React from "react";

import Flex from "../Flex";
import Text from "../Text";

function Button({ children, accessory, isDark = true, ...rest }) {
  return (
    <Flex
      height={52}
      px={3}
      py={2}
      bg={isDark ? "green" : "white"}
      borderRadius={4}
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      {accessory && accessory}
      <Text
        fontSize={2}
        color={isDark ? "white" : "green"}
        fontFamily="roboto"
        fontWeight="medium"
        textDecoration="none"
      >
        {children}
      </Text>
    </Flex>
  );
}

export default Button;
