import React from "react";

import Flex from "../../components/Flex";
import Box from "../../components/Box";
import Text from "../../components/Text";
import Image from "../../components/Image";
import Wrapper from "../../components/Wrapper";

import pollImage from "./images/poll.png";
import pollImage2x from "./images/poll2x.png";

import SettingsSchedule from "./SettingsSchedule";

var Settings = () => {
  return (
    <Box bg="lightPink" id="settings">
      <Wrapper py={6} px={4}>
        <Flex
          flex="1"
          justifyContent="stretch"
          alignItems="center"
          flexDirection={["column", "column", "row", "row"]}
        >
          <Flex flex={1} justifyContent="center" alignItems="center">
            <Box>
              <Text
                pb={[2, 2, 2, 3]}
                fontSize={[3, 3, 3, 4]}
                letterSpacing={[-1, -1, -1, -1]}
                color="black"
                fontFamily="gt"
                fontWeight="bold"
                textAlign={["center", "center", "left", "left"]}
                lineHeight={1.2}
                maxWidth={340}
              >
                Every day we’ll post a poll… When and where would you like this
                to appear?
              </Text>
              <Text
                pb={3}
                fontSize={[1, 1, 1, 2]}
                color="black"
                fontFamily="roboto"
                fontWeight="regular"
                lineHeight={1.5}
                textAlign={["center", "center", "left", "left"]}
                maxWidth={[360, 360, 360, 360]}
              >
                We recommend picking 1 hour before everyone goes home to ensure
                no one misses the poll.
              </Text>
              <SettingsSchedule />
            </Box>
          </Flex>
          <Flex
            flex={1}
            alignItems="center"
            mt={[5, 0]}
            justifyContent="center"
          >
            <Image
              src={pollImage}
              alt="Poll Slack Example"
              srcSet={pollImage + " 1x," + pollImage2x + " 2x"}
              maxWidth="100%"
            />
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
};

export default Settings;
