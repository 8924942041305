import React from "react";

import Box from "../../components/Box";
import Menu from "../../components/Menu";
import Text from "../../components/Text";
import Page from "../../components/Page";
import Link from "../../components/Link";
import Flex from "../../components/Flex";
import Wrapper from "../../components/Wrapper";

function Privacy() {
  const Title = ({ children }) => {
    return (
      <Text
        pb={2}
        fontSize={[2, 3, 3, 3]}
        color="black"
        fontWeight="600"
        lineHeight={1.4}
        maxWidth={800}
        marginLeft="auto"
        marginRight="auto"
        as="h2"
      >
        {children}
      </Text>
    );
  };

  const Paragraph = ({ children }) => {
    return (
      <Text
        pb={4}
        fontSize={[2, 3, 3, 3]}
        color="black"
        fontWeight="400"
        lineHeight={1.4}
        maxWidth={800}
        marginLeft="auto"
        marginRight="auto"
        as="p"
      >
        {children}
      </Text>
    );
  };
  return (
    <Page
      metaData={{
        title: "Terms"
      }}
    >
      <Box bg="green">
        <Menu />
        <Wrapper px={4}>
          <Flex flexDirection="column" mt={5}>
            <Box>
              <Text
                pb={3}
                letterSpacing={-2}
                fontSize={[5, 6, 7, 7]}
                color="white"
                fontFamily="gt"
                fontWeight="bold"
                lineHeight={1.1}
                maxWidth={800}
                marginLeft="auto"
                marginRight="auto"
                as="h1"
              >
                Terms of Service
              </Text>
            </Box>
          </Flex>
        </Wrapper>
      </Box>
      <Wrapper px={4} py={5}>
        <Title>1. Terms</Title>
        <Paragraph>
          By accessing the website at{" "}
          <Link href="https://greenlistapp.com">greenlistapp.com</Link>, you are
          agreeing to be bound by these terms of service, all applicable laws
          and regulations, and agree that you are responsible for compliance
          with any applicable local laws. If you do not agree with any of these
          terms, you are prohibited from using or accessing this site. The
          materials contained in this website are protected by applicable
          copyright and trademark law.
        </Paragraph>
        <Title>2. Use License</Title>
        <Paragraph>
          <ol type="a">
            <li>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on Greenlist's website for
              personal, non-commercial transitory viewing only. This is the
              grant of a license, not a transfer of title, and under this
              license you may not:
              <ol type="i">
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </li>
                <li>
                  attempt to decompile or reverse engineer any software
                  contained on Greenlist's website;
                </li>
                <li>
                  remove any copyright or other proprietary notations from the
                  materials; or
                </li>
                <li>
                  transfer the materials to another person or "mirror" the
                  materials on any other server.
                </li>
              </ol>
            </li>
            <li>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by Greenlist at any time.
              Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </li>
          </ol>
        </Paragraph>

        <Title>3. Disclaimer</Title>
        <Paragraph>
          <ol type="a">
            <li>
              The materials on Greenlist's website are provided on an 'as is'
              basis. Greenlist makes no warranties, expressed or implied, and
              hereby disclaims and negates all other warranties including,
              without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights.
            </li>
            <li>
              Further, Greenlist does not warrant or make any representations
              concerning the accuracy, likely results, or reliability of the use
              of the materials on its website or otherwise relating to such
              materials or on any sites linked to this site.
            </li>
          </ol>
        </Paragraph>

        <Title>4. Limitations</Title>
        <Paragraph>
          In no event shall Greenlist or its suppliers be liable for any damages
          (including, without limitation, damages for loss of data or profit, or
          due to business interruption) arising out of the use or inability to
          use the materials on Greenlist's website, even if Greenlist or a
          Greenlist authorized representative has been notified orally or in
          writing of the possibility of such damage. Because some jurisdictions
          do not allow limitations on implied warranties, or limitations of
          liability for consequential or incidental damages, these limitations
          may not apply to you.
        </Paragraph>

        <Title>5. Accuracy of materials</Title>
        <Paragraph>
          The materials appearing on Greenlist's website could include
          technical, typographical, or photographic errors. Greenlist does not
          warrant that any of the materials on its website are accurate,
          complete or current. Greenlist may make changes to the materials
          contained on its website at any time without notice. However Greenlist
          does not make any commitment to update the materials.
        </Paragraph>

        <Title>6. Links</Title>
        <Paragraph>
          Greenlist has not reviewed all of the sites linked to its website and
          is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement by Greenlist of the
          site. Use of any such linked website is at the user's own risk.
        </Paragraph>

        <Title>7. Modifications</Title>
        <Paragraph>
          Greenlist may revise these terms of service for its website at any
          time without notice. By using this website you are agreeing to be
          bound by the then current version of these terms of service.
        </Paragraph>

        <Title>8. Governing Law</Title>
        <Paragraph>
          These terms and conditions are governed by and construed in accordance
          with the laws of London and you irrevocably submit to the exclusive
          jurisdiction of the courts in that State or location.
        </Paragraph>
      </Wrapper>
    </Page>
  );
}

export default Privacy;
