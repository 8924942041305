import React from "react";

import Text from "../../components/Text";
import RouterLink from "../../components/RouterLink";
import Link from "../../components/Link";

const HeaderItem = ({ to, children, isExternal }) => {
  const link = (
    <Text
      color={"black"}
      fontFamily="roboto"
      fontSize={[1, 2, 2, 2]}
      fontWeight="medium"
      px={[2, 3, 3, 3]}
    >
      {children}
    </Text>
  );

  if (isExternal) {
    return <Link href={to}>{link}</Link>;
  }

  return <RouterLink to={to}>{link}</RouterLink>;
};

export default HeaderItem;
