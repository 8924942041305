import React from "react";

import RouterLink from "../RouterLink";

const Item = ({ to, children, isDark }) => {
  return (
    <RouterLink
      to={to}
      kind="light"
      fontFamily="roboto"
      fontSize={[1, 2, 2, 2]}
      fontWeight="medium"
      pl={[3, 4, 4, 4]}
    >
      {children}
    </RouterLink>
  );
};

export default Item;
