import React from "react";
import { SelectElement } from "./styles";

import { sizes } from "./sizes";

function Select({ children, size = 0, ...rest }) {
  return (
    <SelectElement
      color="black"
      border="1px solid"
      bg="transparent"
      borderColor="gray"
      fontWeight={500}
      {...sizes[size]}
      {...rest}
    >
      {children}
    </SelectElement>
  );
}

export default Select;
