import styled from "styled-components";
import check from "./images/check.svg";

export const CheckboxElement = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  input {
    display: none;
    position: absolute;
  }
  input:checked ~ .check {
    border-color: #000;
    box-shadow: 0px 0px 0px 15px #000 inset;
  }
  input:checked ~ .check::after {
    opacity: 1;
    transform: scale(1);
  }
  .check {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 18px;
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: 0px 0px 0px 0px #000 inset;
  }
  .check::after {
    content: "";
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 4;
    position: absolute;
    transform: scale(0);
    background-size: 50%;
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: center;
  }
`;
