import React from "react";

import Text from "../../../components/Text";
import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import Wrapper from "../../../components/Wrapper";
import Image from "../../../components/Image";

function FeatureBlock({
  title,
  subtitle,
  image1x,
  image2x,
  alt,
  maxWidthTitle = 450,
  flipped = false,
  imageOffsetTop = 0,
  imageOffsetBottom = 0,
  ...rest
}) {
  const flexDirectionLarge = flipped ? "row-reverse" : "row";
  const flexDirectionSmall = "column";

  return (
    <Box bg="white" {...rest}>
      <Wrapper py={0} px={4}>
        <Flex
          flex="1"
          justifyContent="stretch"
          alignItems="center"
          flexDirection={[
            flexDirectionSmall,
            flexDirectionSmall,
            flexDirectionLarge,
            flexDirectionLarge
          ]}
        >
          <Flex flex={1} justifyContent="center" alignItems="center">
            <Box>
              <Text
                pb={[2, 2, 2, 3]}
                fontSize={[4, 4, 4, 5]}
                letterSpacing={[-1, -1, -1, -2]}
                color="black"
                fontFamily="gt"
                fontWeight="bold"
                textAlign={["center", "center", "left", "left"]}
                lineHeight={1.2}
                as="h2"
                maxWidth={maxWidthTitle}
              >
                {title}
              </Text>
              <Text
                pb={3}
                fontSize={[2, 2, 2, 3]}
                color="black"
                fontFamily="roboto"
                fontWeight="regular"
                lineHeight={1.5}
                textAlign={["center", "center", "left", "left"]}
                maxWidth={[350, 350, 400, 400]}
                as="p"
              >
                {subtitle}
              </Text>
            </Box>
          </Flex>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <Image
              src={image1x}
              alt={alt}
              srcSet={image1x + " 1x," + image2x + " 2x"}
              maxWidth="100%"
              mt={imageOffsetTop}
              mb={imageOffsetBottom}
            />
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
}

export default FeatureBlock;
