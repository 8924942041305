import React, { useState, useCallback, useEffect } from "react";

import {
  updateUser,
  fetchUser,
  allSlackChannels,
  availableTimeZones
} from "../../api";
import { useAuth } from "../../context/auth";

import Box from "../../components/Box";
import Flex from "../../components/Flex";
import Checkbox from "../../components/Checkbox";
import Select from "../../components/Select";
import Text from "../../components/Text";

function SettingsSchedule() {
  const { authToken } = useAuth();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [schedule, setSchedule] = useState(undefined);
  const [timezones, setTimezones] = useState(undefined);
  const [slackChannels, setSlackChannels] = useState(undefined);

  var fetchSettings = useCallback(() => {
    fetchUser({ token: authToken })
      .then(resp => resp.json())
      .then(function(data) {
        setSchedule(data.team.schedule);
        setIsLoading(false);
      })
      .catch(error => {
        if (error) {
          setErrorMessage("Something went wrong fetching the plans...");
        }
      });
  }, [authToken]);

  var fetchSlackChannels = useCallback(() => {
    allSlackChannels({ token: authToken })
      .then(resp => resp.json())
      .then(function(data) {
        setSlackChannels(data);
        setIsLoading(false);
      })
      .catch(error => {
        if (error) {
          setErrorMessage(
            "Something went wrong fetching the slack channels..."
          );
        }
      });
  }, [authToken]);

  var fetchAvailableTimeZones = useCallback(() => {
    availableTimeZones({})
      .then(resp => resp.json())
      .then(function(data) {
        setTimezones(data);
        setIsLoading(false);
      })
      .catch(error => {
        if (error) {
          setErrorMessage("Something went wrong fetching the plans...");
        }
      });
  }, []);

  var updateSettings = newSchedule => {
    updateUser({
      token: authToken,
      schedule: newSchedule
    }).catch(error => {
      if (error) {
        setErrorMessage("Something went wrong updating your plans...");
      }
    });
  };

  useEffect(() => {
    fetchSettings();
    fetchAvailableTimeZones();
    fetchSlackChannels();
  }, [fetchSettings, fetchAvailableTimeZones, fetchSlackChannels]);

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  if (
    isLoading ||
    timezones === undefined ||
    schedule === undefined ||
    slackChannels === undefined
  ) {
    return <div>loading</div>;
  }

  const Day = ({ name, valueKey }) => {
    return (
      <Flex
        px={10}
        pb={10}
        pt={12}
        bg="white"
        borderRadius={8}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        boxShadow="0 2px 10px 0 rgba(0,0,0,0.10)"
      >
        <Text color="black" fontSize={0} pb={1} textAlign="center">
          {name}
        </Text>
        <Box py={1}>
          <Checkbox
            checked={schedule[valueKey]}
            onChange={checked => {
              let newSchedule = { ...schedule, [valueKey]: checked };
              setSchedule(newSchedule);
              updateSettings(newSchedule);
            }}
          />
        </Box>
      </Flex>
    );
  };

  const hours = Array.from({ length: 24 }, (_, i) => i);

  return (
    <Box maxWidth={365}>
      <Flex justifyContent="space-between" width="100%">
        <Day name="MON" valueKey="monday" />
        <Day name="TUE" valueKey="tuesday" />
        <Day name="WED" valueKey="wednesday" />
        <Day name="THU" valueKey="thursday" />
        <Day name="FRI" valueKey="friday" />
        <Day name="SAT" valueKey="saturday" />
        <Day name="SUN" valueKey="sunday" />
      </Flex>

      <Box borderTop="1px solid" borderColor="gray" mt={4} pt={4}>
        <Flex alignItems="center" pb={3}>
          <Text
            color="black"
            fontWeight="bold"
            fontSize={1}
            mr={2}
            minWidth={70}
          >
            Channel
          </Text>
          <Select
            name="slack_channel"
            width={1}
            value={schedule.slack_channel}
            onChange={event => {
              let newSchedule = {
                ...schedule,
                slack_channel: event.target.value
              };
              setSchedule(newSchedule);
              updateSettings(newSchedule);
            }}
          >
            {slackChannels.map((channel, index) => (
              <option key={index} value={channel.id}>
                #{channel.name}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Flex alignItems="center" mr={3}>
            <Text
              color="black"
              fontWeight="bold"
              fontSize={1}
              mr={2}
              minWidth={70}
            >
              Timezone
            </Text>
            <Select
              name="timezone"
              width={1}
              value={schedule.timezone}
              onChange={event => {
                let newSchedule = {
                  ...schedule,
                  timezone: event.target.value
                };
                setSchedule(newSchedule);
                updateSettings(newSchedule);
              }}
            >
              {timezones.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex flexGrow="1" alignItems="center">
            <Text color="black" fontWeight="bold" fontSize={1} mr={2}>
              Time
            </Text>
            <Select
              name="hour"
              value={schedule.hour}
              onChange={event => {
                let newSchedule = {
                  ...schedule,
                  hour: parseInt(event.target.value)
                };
                setSchedule(newSchedule);
                updateSettings(newSchedule);
              }}
            >
              {hours.map((number, index) => (
                <option key={index} value={number}>
                  {number}:00
                </option>
              ))}
            </Select>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export default SettingsSchedule;
