import React from "react";
import { useInView } from "react-intersection-observer";

import Text from "../../../components/Text";
import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import Wrapper from "../../../components/Wrapper";
import Image from "../../../components/Image";
import AnimateSlideIn from "../../../components/AnimateSlideIn";
import AnimateTextUp from "../../../components/AnimateTextUp";

import ui from "./images/ui.png";
import ui2x from "./images/ui@2x.png";

function ScreenshotBlock() {
  const [ref, inView] = useInView({
    // https://github.com/thebuilder/react-intersection-observer#options
    threshold: 0,
    triggerOnce: true
  });

  return (
    <Box bg="lightPink">
      <Wrapper pt={[5, 6, 180, 180]} px={4}>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width={1}
        >
          <Box>
            <Text
              pb={[3, 4, 4, 4]}
              fontSize={[4, 5, 6, 6]}
              letterSpacing={[-1, -3, -3, -3]}
              color="black"
              fontFamily="gt"
              fontWeight="bold"
              lineHeight={1.1}
              textAlign="center"
              ref={ref}
              as="h2"
            >
              <AnimateTextUp startAnimation={inView}>
                Create a cleaner and
              </AnimateTextUp>

              <AnimateTextUp startAnimation={inView} delay={0.3}>
                better world, on Slack.{" "}
              </AnimateTextUp>
            </Text>
            <Text
              pb={3}
              fontSize={[2, 2, 3, 3]}
              color="black"
              fontFamily="roboto"
              fontWeight="regular"
              lineHeight={1.5}
              maxWidth={[400, 580, 580, 580]}
              textAlign="center"
              mx="auto"
              as="p"
            >
              Whether it’s choosing reusable food and liquid containers or
              picking the vegetarian option for the office lunch, Greenlist
              allows anyone in your team to set a positive role model and
              influence other staff in being more cautious about plastic
              pollution and using resources sustainably.
            </Text>
          </Box>
          <Flex flex="1" justifyContent="center" alignItems="center">
            <AnimateSlideIn>
              <Box
                borderRadius={[5, 5, 10, 10]}
                overflow="hidden"
                boxShadow="0 20px 60px 0 rgba(0,0,0,0.10)"
                mt={[4, 5, 5, 5]}
                mb={[-5, -6, -6, -6]}
              >
                <Image
                  src={ui}
                  alt="Slack Greenlist UI"
                  srcSet={ui + " 1x," + ui2x + " 2x"}
                  maxWidth="100%"
                />
              </Box>
            </AnimateSlideIn>
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
}

export default ScreenshotBlock;
