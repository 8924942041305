import React from "react";

import Header from "./Header";
import MagazineBlock from "./MagazineBlock";
import ScreenshotBlock from "./ScreenshotBlock";
import FeatureBlock from "./FeatureBlock";

import BigCopyBlock from "../../components/BigCopyBlock";
import Box from "../../components/Box";
import Page from "../../components/Page";
import Menu from "../../components/Menu";
import Text from "../../components/Text";
import RouterLink from "../../components/RouterLink";

import ScrollToTop from "../../components/ScrollToTop";

import bestClass from "./images/best-class.png";
import bestClass2x from "./images/best-class@2x.png";

import collectPoints from "./images/collect-points.png";
import collectPoints2x from "./images/collect-points@2x.png";

import unlockBadges from "./images/unlock-badges.png";
import unlockBadges2x from "./images/unlock-badges@2x.png";

function Home() {
  return (
    <Page>
      <ScrollToTop />
      <Box bg="green">
        <Menu />
        <Header />
      </Box>
      <MagazineBlock />
      <ScreenshotBlock />
      <FeatureBlock
        pt={250}
        title="Educate employees"
        subtitle="Thanks to a point system based on the environmental impact of each action, Greenlist helps you educate your employees on sustainability while keeping them engaged."
        image1x={collectPoints}
        image2x={collectPoints2x}
        alt="Slack voting example"
        imageOffsetTop={[0, 0, -5, -5]}
        imageOffsetBottom={[0, 0, -5, -5]}
      />
      <FeatureBlock
        title="Celebrate achievements"
        subtitle="Greenlist celebrates your employee’s waste reduction achievements by giving custom designed badges so that they feel proud of the quantifiable difference they are making towards the plastic pollution crisis."
        flipped={true}
        image1x={unlockBadges}
        image2x={unlockBadges2x}
        alt="3 badges"
        imageOffsetTop={[-5, -5, -5, -5]}
        imageOffsetBottom={[-3, -3, -5, -5]}
      />
      <FeatureBlock
        title="Drive engagement"
        subtitle="Discover week by week how well your team is doing and which employee will get to the top of the monthly Green Leaderboard!"
        image1x={bestClass}
        image2x={bestClass2x}
        alt="Ranking board in Slack"
        maxWidthTitle={350}
        imageOffsetTop={[0, 0, -5, -5]}
        imageOffsetBottom={[0, 0, -5, -5]}
      />
      <BigCopyBlock mt={[4, 4, 6, 6]}>
        Sign up to
        <Text display="inline-block" color="pink">
          Greenlist
        </Text>{" "}
        today.
      </BigCopyBlock>
      <Box bg="green" textAlign="center" color="white" p={4}>
        <RouterLink to="/privacy" color="white">
          Privacy
        </RouterLink>{" "}
        &{" "}
        <RouterLink to="/terms" color="white">
          Terms
        </RouterLink>
      </Box>
    </Page>
  );
}

export default Home;
