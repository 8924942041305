import React from "react";

import Text from "../Text";
import Box from "../Box";
import SlackButton from "../SlackButton";
import Flex from "../Flex";
import Wrapper from "../Wrapper";

function BigCopyBlock({ children, ...rest }) {
  return (
    <Box bg="green" {...rest}>
      <Wrapper px={4}>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width={1}
          py={[6, 6, 6, 6]}
        >
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Text
              pb={[2, 3, 3, 3]}
              color="white"
              fontFamily="gt"
              fontWeight="bold"
              lineHeight={1.0}
              letterSpacing={-2}
              fontSize={[5, 6, 6, 7]}
              textAlign="center"
              maxWidth={800}
            >
              {children}
            </Text>
            <SlackButton isDark={false} />
            <Text color="white" mt={3} fontSize={1}>
              Free 14 days trial, no credit card required.
            </Text>
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
}

export default BigCopyBlock;
