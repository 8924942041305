import React from "react";
import { Helmet } from "react-helmet";

import Box from "../Box";

function Page({ metaData, children, ...rest }) {
  const defaultTitle = `Greenlist - Green Revolution. It’s a team effort`;
  return (
    <Box {...rest}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {metaData && metaData.title
            ? `${metaData.title} - Greenlist`
            : defaultTitle}
        </title>
      </Helmet>
      {children}
    </Box>
  );
}

export default Page;
