import React from "react";

import Box from "../../components/Box";

var Card = ({ children, ...rest }) => {
  return (
    <Box
      maxWidth={389}
      width={1}
      display="flex"
      flexDirection="column"
      bg="white"
      borderRadius={10}
      px={40}
      pt={30}
      pb={40}
      boxShadow="0 20px 60px 0 rgba(0,0,0,0.10)"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Card;
