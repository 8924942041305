import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import queryString from "query-string";

import { useAuth } from "./context/auth";
import { fetchUser } from "./api";

function Login({ match, location }) {
  const { setAuthToken } = useAuth();
  const [tokenIsValid, setTokenIsValid] = useState(null);
  const parsed = queryString.parse(location.search);
  const token = decodeURI(parsed.token);
  useEffect(() => {
    fetchUser({ token: token })
      .then(resp => resp.json())
      .then(function(data) {
        setAuthToken(token);
        setTokenIsValid(true);
      })
      .catch(error => setTokenIsValid(false));
  }, [token, setAuthToken]);

  if (tokenIsValid === true) {
    let next = parsed.next ? parsed.next : "/manage";
    return <Redirect to={next} />;
  }

  if (tokenIsValid === false) {
    return <div>Invalid token</div>;
  }

  return <div>Loading</div>;
}

export default withRouter(Login);
