import React from "react";

import Box from "../Box";
function AspectRatio({ children, ratio = 1 / 1, ...rest }) {
  return (
    <Box width="100%" pt={`${ratio * 100}%`} position="relative" {...rest}>
      <Box top={0} left={0} bottom={0} right={0}>
        {children}
      </Box>
    </Box>
  );
}

export default AspectRatio;
