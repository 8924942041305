import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { apiUrl } from "../../../environment";

import Text from "../../../components/Text";
import SlackButton from "../../../components/SlackButton";
import Box from "../../../components/Box";
import Flex from "../../../components/Flex";
import Wrapper from "../../../components/Wrapper";
import AnimateTextUp from "../../../components/AnimateTextUp";

import AnimatedCards from "./AnimatedCards";

function Home() {
  const [ref, inView] = useInView({
    // https://github.com/thebuilder/react-intersection-observer#options
    threshold: 0,
    triggerOnce: true
  });

  return (
    <Box>
      <Wrapper px={4}>
        <Flex
          minHeight={800}
          position="relative"
          justifyContent="center"
          alignItems="stretch"
          flexDirection={["column", "column", "column", "row"]}
          mt={[5, 5, 5, 0]}
        >
          <Flex width={[1, 1, 1, 0.5]} alignItems="center">
            <Box mb={5}>
              <Text
                pb={4}
                letterSpacing={-2}
                fontSize={[5, 5, 5, 6]}
                color="white"
                fontFamily="gt"
                fontWeight="bold"
                lineHeight={1.0}
                maxWidth={948}
                marginLeft="auto"
                marginRight="auto"
                as="h1"
                ref={ref}
              >
                <AnimateTextUp startAnimation={inView} delay={0.2}>
                  Give your team
                </AnimateTextUp>
                <AnimateTextUp startAnimation={inView} delay={0.4}>
                  the tools to be
                </AnimateTextUp>
                <AnimateTextUp startAnimation={inView} delay={0.6}>
                  more{" "}
                  <Box display="inline-block" color="pink">
                    sustainable
                  </Box>
                </AnimateTextUp>
                <AnimateTextUp startAnimation={inView} delay={0.8}>
                  at work
                </AnimateTextUp>
              </Text>
              <AnimateTextUp startAnimation={inView} delay={1.1}>
                <Text
                  fontSize={[2, 2, 3, 3]}
                  color="white"
                  fontFamily="roboto"
                  fontWeight="regular"
                  lineHeight={1.5}
                  maxWidth={500}
                  as="p"
                  pb={3}
                >
                  Greenlist is a simple Slack plugin that encourages your teams
                  to care for our planet in a way that feels inspiring and fun.
                  Using votes, badges, and score boards Greenlist aims to reward
                  greener choices and unite your employees in the battle against
                  climate change.
                </Text>
              </AnimateTextUp>
              <AnimateTextUp startAnimation={inView} delay={1.4}>
                <SlackButton
                  pt={1}
                  isDark={false}
                  url={`${apiUrl}signup/slack`}
                />
              </AnimateTextUp>
            </Box>
          </Flex>
          <Flex width={[1, 1, 1, 0.5]} position="relative">
            <Flex
              flexGrow={1}
              justifyContent={[
                "flex-start",
                "flex-start",
                "flex-start",
                "flex-end"
              ]}
              alignItems="center"
              width={1}
              zIndex={1}
              position="relative"
            >
              <motion.div
                animate={
                  inView && {
                    opacity: 1
                  }
                }
                initial={{
                  opacity: 0
                }}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                  delay: 1
                }}
              >
                <AnimatedCards />
              </motion.div>
            </Flex>
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
}

export default Home;
