import React from "react";
import { motion } from "framer-motion";
import { withTheme } from "styled-components";

const AnimateFadeIn = ({
  children,
  x = 0,
  startAnimation = true,
  theme,
  delay = 0
}) => {
  const initial = { opacity: 0 };
  const animate = { opacity: 1 };
  const transition = {
    ease: theme.easings.smooth.array,
    duration: 0.5,
    delay: delay
  };

  return (
    <motion.div
      initial={initial}
      overflow={"hidden"}
      animate={startAnimation && animate}
      transition={{ ...transition }}
    >
      {children}
    </motion.div>
  );
};

export default withTheme(AnimateFadeIn);
