import React, { useState, useEffect, useCallback } from "react";
import { fetchUser } from "../../api";
import { useAuth } from "../../context/auth";

import Text from "../../components/Text";
import Box from "../../components/Box";
import Flex from "../../components/Flex";
import Wrapper from "../../components/Wrapper";
import Image from "../../components/Image";

import badgesImage from "./images/badges.png";
import badgesImage2x from "./images/badges2x.png";

function Badges() {
  const { authToken } = useAuth();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [badgesCount, setBadgesCount] = useState(undefined);

  var fetchData = useCallback(() => {
    fetchUser({ token: authToken })
      .then(resp => resp.json())
      .then(function(data) {
        setBadgesCount(data.team.stats.badges_count);
        setIsLoading(false);
      })
      .catch(error => {
        if (error) {
          setErrorMessage("Something went wrong fetching the plans...");
        }
      });
  }, [authToken]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  if (isLoading) {
    return <div>loading</div>;
  }

  return (
    <Box bg="white" id="badges">
      <Wrapper py={6} px={4}>
        <Flex
          flex="1"
          justifyContent="stretch"
          alignItems="center"
          flexDirection={["column", "column", "row-reverse", "row-reverse"]}
        >
          <Flex flex={1} justifyContent="center" alignItems="center">
            <Box>
              <Text
                fontSize={[3, 3, 3, 4]}
                letterSpacing={[-1, -1, -1, -1]}
                color="black"
                fontFamily="gt"
                fontWeight="bold"
                textAlign={["center", "center", "left", "left"]}
                lineHeight={1.2}
              >
                Your team collected
              </Text>
              <Text
                fontSize={[5, 5, 5, 6]}
                letterSpacing={[-1, -1, -1, -2]}
                color="black"
                fontFamily="gt"
                fontWeight="bold"
                textAlign={["center", "center", "left", "left"]}
                lineHeight={1.2}
              >
                {badgesCount} badges
              </Text>
              <Text
                pb={[2, 2, 2, 3]}
                fontSize={[3, 3, 3, 4]}
                letterSpacing={[-1, -1, -1, -2]}
                color="black"
                fontFamily="gt"
                fontWeight="bold"
                textAlign={["center", "center", "left", "left"]}
                lineHeight={1.2}
              >
                So far...
              </Text>
              <Text
                pb={3}
                fontSize={[1, 1, 1, 2]}
                color="black"
                fontFamily="roboto"
                fontWeight="regular"
                lineHeight={1.5}
                textAlign={["center", "center", "left", "left"]}
                maxWidth={[280, 280, 320, 320]}
              >
                Keep voting on the daily poll to receive more badges and unlock
                them all!
              </Text>
            </Box>
          </Flex>
          <Flex flex={1} alignItems="center" justifyContent="center">
            <Image
              src={badgesImage}
              alt="Badges received by people"
              srcSet={badgesImage + " 1x," + badgesImage2x + " 2x"}
              maxWidth="100%"
              mt={[4, 4, 0, 0]}
            />
          </Flex>
        </Flex>
      </Wrapper>
    </Box>
  );
}

export default Badges;
