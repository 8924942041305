import React from "react";

import Page from "../../components/Page";
import Questions from "../Pricing/Questions";

import Subscription from "./Subscription";
import Header from "./Header";
import Settings from "./Settings";
import Badges from "./Badges";

var ManagePage = ({ children }) => {
  return (
    <Page metaData={{ title: "Manage your subscription" }}>
      <Header />
      <Settings />
      <Badges />
      <Subscription />
      <Questions my={[6, 6, 6, 6]} />
    </Page>
  );
};

export default ManagePage;
